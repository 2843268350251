<template>
 <v-dialog
    v-model="dialogEspera"
    hide-overlay
    persistent
    width="300"
    >
    <v-card
        color="primary"
        dark
    >
        <v-card-text>
        Espere un momento por favor
        <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
        ></v-progress-linear>
        </v-card-text>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: 'DialogEspera',
    props: {
        dialogEspera: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
        }
    },
}
</script>

<style>

</style>